<script setup>
import { ref } from "vue";

const props = defineProps({
  callback: Function,
  msg: Object,
  title: String,
});

const dialog = ref(true);
</script>

<template>
  <div class="text-center">
    <v-dialog v-model="dialog" max-width="500" persistent>
      <v-card :text="msg.message" :title="title">
        <template v-slot:prepend>
          <v-icon :color="msg.color" icon="mdi-alert"></v-icon>
        </template>

        <v-card-text v-if="$slots.default">
          <slot />
        </v-card-text>

        <template v-slot:actions>
          <v-spacer></v-spacer>

          <v-btn variant="tonal" @click="callback(false, msg)"> Cancel </v-btn>

          <v-btn color="primary" variant="flat" @click="callback(true, msg)">
            Confirm
          </v-btn>
        </template>
      </v-card>
    </v-dialog>
  </div>
</template>
